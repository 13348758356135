:root {
    --border: rgba(163, 163, 163, 0.502);
    --disable: rgb(255, 255, 255);
    --enable: rgb(2, 173, 145);
}

.report-filter.collapsed {
    background-color: white;
    z-index: 100;
    left: 0;
    top: 40vh;
    border-radius: 0;
    width: 160px;
    border: none;
    height: auto;
    display: flex;
}

.collapsed p {
    background-color: white !important;
    text-align: left;
    color: black !important;
}

.collapsed button {
    width: 30px;
    height: 30px;
    margin-top: 4px;
    background-color: white;
    border: none;
}

.report-filter {
    justify-content: center;
    width: 300px;
    max-width: 252px;
    padding: 8px 24px;
    z-index: 100;
    position: absolute;
    background-color: rgb(255, 255, 255);
    left: 0px;
    top: 60px;
    height: calc(100vh - 80px);
    margin: 0;
    overflow: scroll;
    box-shadow: inset 0px 8px 12px 0px rgba(213, 213, 213, 0.306);
}

.report-filter h4 {
    font-size: 10px !important;
    font-weight: bold;
    margin: 4px 0px;
    max-width: 100px;
    position: relative;
}

.editable {
    display: flex;
}

.icon.edit {
    cursor: pointer;
    margin-left: 12px;
    width: 20px;
    height: 20px;
    background-color: var(--border);
    -webkit-mask-image: url(../../../images/icons/edit.svg);
    mask-image: url(../../../images/icons/edit.svg);
}

.icon.edit:hover {
    background-color: #0B6825;
}

.report-filter p {
    font-size: 12px;
    margin-top: 2px;
    text-align: left;
    width: auto;
    padding: 0px 0px 6px 0px;
    color: gray;
    border-radius: 8px;
}

.report-filter select {
    margin-top:  4px;
    cursor: pointer;
    width: 90%;
    text-align: center;
    padding: 8px 4px;
    font-size: 12px;
    border-radius: 6px;
    border: 1px solid rgba(128, 128, 128, 0.402);
    text-indent: 8px;
}

.report-filter input {
    margin-top: 0;
    cursor: pointer;
    width: 86%;
    text-align: center;
    padding: 8px 4px;
    font-size: 12px;
    border-radius: 6px;
    border: 1px solid rgba(128, 128, 128, 0.402);
    text-indent: 8px;
}

.select-grid-1 select {
    width: 77%;
    margin: 0px 4px 0px 0px;
}

.select-grid-2 select {
    width: 36%;
    margin: 4px;
}

.multi-options {
    margin-top: 4px;
    display: flex;
    max-width: 90%;
    flex-wrap: wrap;
}

.multi-options .option {
    display: flex;
    width: fit-content;
    margin: 4px;
    border-radius: 8px;
    color: #0B6825;
    padding: 6px 4px 6px 12px;
    background-color: #CFF465;
    box-shadow: 0px 2px 2px 0px rgba(95, 95, 95, 0.6);
}

.option p {
    margin: 0;
    font-size: 10px;
    padding-top: 1px;
    padding-bottom: 0;
    font-weight: bold;
    margin-right: 4px;
    width: fit-content;
    color: #0B6825;
    background-color: transparent;
}

.option button {
    cursor: pointer;
    font-size: 10px;
    width: 15px !important;
    max-width: 15px;
    height: 14px !important;
    margin: 0 !important;
    margin-top: 1px !important;
    margin-left: 5px !important;
    padding: 0px;
    align-content: center;
    text-align: center;
    border: none;
}

.option button:hover {
    background-color: #17ee54;
}

.icon {
    border: none;
    height: 40px;
    -webkit-mask-repeat: no-repeat;
    mask-repeat: no-repeat;
    -webkit-mask-size: contain;
    mask-size: contain;
    width: 40px;
}

.icon-cancel {
    background-color: #0B6825;
    -webkit-mask-image: url(../../../images/icons/cancel.svg);
    mask-image: url(../../../images/icons/cancel.svg);
}

.add-btn {
    background-color: #CFF465;
    border: 2px solid #0B6825;
    border-radius: 4px;
    color: #0B6825;
    padding: 4px 8px;
}

.add-btn::before {
    content: '+';
}

.add-btn:hover {
    cursor: pointer;
    color: #CFF465;
    background-color: #0B6825;
}

.generate-btn {
    width: 90%;
    margin: 4px 0px 32px;
    padding: 12px 0px;
    border-radius: 6px;
    background-color: #CFF465;
    border: none;
    font-weight: bold;
    color: #0B6825;
    cursor: pointer;
}

.generate-btn:hover {
    background-color: #c4f344;
}

.generate-btn.disable {
    background-color: rgb(214, 214, 214);
    color: gray;
}

.generate-btn.disable:hover {
    background-color: rgb(214, 214, 214);
    color: gray;
}

.generate-btn.disable:active {
    background-color: rgb(214, 214, 214);
    color: gray;
}

.generate-btn:active {
    transform: translateY(0);
    background-color: #0B6825;
    color: #CFF465;
    box-shadow: none;
}


.refresh-btn {
    position: absolute;
    top: 12px;
    font-size: 11px;
    right: 12px;
    background-color: transparent;
    border: none;
    border-radius: 6px;
    color: #0b3668;
    cursor: pointer;
}

.refresh-btn:hover {
    color: #2ca7b6;
}


.report-types {
    display: grid;
    width: 90%;
    grid-template-columns: repeat(3, 33%);
    margin-bottom: 20px;
}

.report-types h1 {
    cursor: pointer;
    text-align: center;
    font-size: 10px !important;
    background-color: rgb(255, 255, 255);
    padding: 8px 0px;
    color: gray;
    border: 2px solid rgba(128, 128, 128, 0.1);
}

.report-types h1:hover {
    background-color: #677b77;
    color: white;
}

.report-types h1:nth-child(1) {
    border-radius: 50px 0px 0px 50px;
}

.report-types h1:nth-child(3) {
    border-radius: 0px 50px 50px 0px;
}

.selected {
    background-color: #CFF465 !important;
    color: #0B6825 !important;
    border-color: #0B6825 !important;
}

@keyframes enable-toggler {
    from {background-color: white;}
    to {background-color: var(--enable);}
}

@keyframes enable-check {
    from {background-color: white; border-color: rgba(0, 0, 0, 0.219); left: 0px;}
    to {background-color: var(--enable); border-color: var(--enable); left: 8px;}
}

@keyframes disable-toggler {
    from {background-color: var(--enable);}
    to {background-color: white;}
}

@keyframes disable-check {
    from {background-color: var(--enable); border-color: var(--enable); left: 8px;}
    to {background-color: white; border-color: rgba(0, 0, 0, 0.219); left: 0px;}
}


.toggler {
    animation-name: enable-toggler;
    animation-duration: 1s;
    animation-direction: normal;
    animation-iteration-count: 1;
    margin-left: 24px;
    position: relative;
    margin-top: 8px;
    width: 20px;
    background-color: var(--enable);
    height: 6px;
    border-radius: 6px;
}

.check-filter {
    animation-name: enable-check;
    animation-duration: 1s;
    animation-direction: normal;
    animation-iteration-count: 1;
    display: inline-flex;
    position: absolute;
    top: -4px;
    left: 8px;
    width: 12px;
    height: 12px;
    border-radius: 50%;
    background-color: var(--enable);
    border: 1px solid var(--enable);
    cursor: pointer;
}

.toggler.disable {
    animation-name: disable-toggler;
    animation-duration: 1s;
    animation-direction: normal;
    animation-iteration-count: 1;
    background-color: white;
    box-shadow: 0px 0px 2px rgba(0, 0, 0, 0.308);
}



.check-filter.disable {
    animation-name: disable-check;
    animation-duration: 1s;
    animation-direction: normal;
    animation-iteration-count: 1;
    left: 0;
    background-color: white;
    border: 1px solid rgba(0, 0, 0, 0.219);
}

.check-filter:hover {
    box-shadow: 0px 0px 8px rgba(0, 0, 0, 0.329);
}