.login-section {
    padding: 24px;
    position: absolute;
    box-shadow: 0px 0px 12px 0px rgba(0, 0, 0, 0.158);
    border-radius: 12px;
    width: 400px;
    top: calc(50vh - 200px);
    left: calc(50vw - 200px - 24px);
}

.login-section h1 {
    text-align: center;
    font-size: 16px;
    margin-bottom: 24px;
}

.login-section button {
    cursor: pointer;
    border: none;
    background-color: rgb(17, 185, 149);
    padding: 6px 24px;
    border-radius: 4px;
    color: white;
    width: 140px;
    margin-left: calc(50% - 70px - 12px);
}

.login-section button:hover {
    background-color: rgb(37, 192, 140);
}

.loading.login {
    width: calc(120vw) !important;
    max-width: calc(120vw) !important;
}

.loading.icon {
    background-color: gray;
}