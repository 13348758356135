/* The container must be positioned relative: */
.custom-select {
    margin-top: 8px;
    position: relative;
    width: 89%;
    cursor: pointer;
    text-align: center;
    padding: 8px 4px;
    font-size: 12px;
    border-radius: 6px;
    border: 1px solid rgba(128, 128, 128, 0.402);
    text-indent: 8px;
    font-weight: 500;
    min-height: 32px;
    max-height: 32px;
}
.custom-select h1 {
    text-align: center;
    font-size: 12px;
    text-indent: 8px;
    font-weight: 500;
}

.custom-select::after {
    background-color: red;
}

.custom-options {
    z-index: 100;
    width: 102%;
    position: absolute;
    top: -2px;
    background-color: white;
    left: -1px;
    max-height: 180px;
    overflow-y: scroll;
    border: 1px solid rgba(0, 0, 0, 0.158);
    border-radius: 8px;
}

.custom-options.leases {
   top: 44px; 
}

.custom-option {
    padding: 8px 4px;
    font-size: 12px;
    border: 1px solid rgba(0, 0, 0, 0.13);
}

.custom-option:hover {
    background-color: rgb(227, 240, 240);
}

.custom-option span, .selected-collapsed span {
    color: rgb(0, 103, 172) !important;
    
}

.custom-option p, .selected-collapsed p {
    text-align: center !important;
    padding: 0 !important;
    margin: 0 !important;
    color: rgb(27, 27, 27) !important;
}

.selected-collapsed h1{
    padding: 0px 0px;
}

.lease-search {
    position: absolute;
    top: 0px;
    left: 0;
    margin: 0;
    padding: 0;
    width: 100%;
}
.lease-search input {
    border-radius: 6px !important;
    border: 2px solid gray !important;
    cursor: text !important;
    margin: 0 !important;
    width: 99% !important;
    margin-left: -8px !important;
    padding: 12px 0px !important;
    font-size: 14px !important;
    font-weight: bold;
    text-align: center;
}

::placeholder {
    color: rgb(223, 223, 223);
    font-weight: lighter;
}

.selected-collapsed.lease{
    width: 100% !important;
    display: flex;
    padding: 0 !important;
}

.selected-collapsed.lease h1 {
    width: 88%;
}

.custom-select.lease {
    padding: 0 !important;
    display: flex;
}