:root {
    --type-a: 4% 3% 28% 3% 16% 3% 40%;
    --sub-type-a: 4% 3% 7% 7% 7% 7% 3% 16% 3% 20% 20%;
    --type-b: 4% 4% 6% 8% 15% 5% 8% 32%;
    --type-c: 4% 4% 9% 11% 10% 6% 12% 8% 5% 14% 11%;
    --sub-type-c: 4% 4% 9% 11% 10% 6% 12% 8% 5% 7% 7% 18%;
}

.index-0 {
    filter: hue-rotate(45deg);
}

.index-1 {
    filter: saturate(1);
}

.head-line {
    max-width: 96%;
    display: grid;
    margin: 0 !important;
    background-color: white !important;
}

.type-a {
    grid-template-columns: var(--type-a) !important;
}

.type-b {
    grid-template-columns: var(--type-b) !important;
}

.type-c {
    grid-template-columns: var(--type-c) !important;
}

.head-line p {
    font-family: monospace;
    padding: 0 !important;
    border-right: none !important;
    font-weight: bold !important;
    font-size: 10px;
    text-align: center;
}

.sub-head-line {
    max-width: 96%;
    display: grid;
    margin: 0 !important;
    margin-top: -16px !important;
    background-color: white !important;
    margin-bottom: 12px !important;
}

.sub-head-line p {
    font-family: monospace;
    text-align: center;
    padding: 4px 0px !important;
    font-size: 8px !important;
    font-weight: bold !important;
    color: #999999;
    border-right: 1px solid rgba(128, 128, 128, 0.39);
}


.line {
    cursor: pointer;
    margin-bottom: 12px;
    max-width: 96%;
    background-color: #D4E8FF;
    display: grid;
    border-radius: 10px;
}

.line-a {
    grid-template-columns: var(--sub-type-a);
}

.line-b {
    grid-template-columns: var(--type-b);
}

.line-c {
    height: 60px;
    padding-left: 3px;
    grid-template-columns: var(--sub-type-c);
}

.line:hover {
    background-color: #b9d6f8;
}

.line p {
    padding-top: 14px;
    font-size: 10px;
    text-align: center;
    border-right: 1px solid rgba(128, 128, 128, 0.39);
    font-weight: 500;
    font-family: monospace;
}

.volumes p {
    font-family: monospace;
    margin-left: 10px;
    padding-top: 0;
    font-weight: bold;
    text-align: left !important;
    border-right: none !important;
}

.volumes span {
    font-weight: lighter;
    color: rgb(86, 97, 118);
}

.accent {
    color: #0219E7 !important;
    font-weight: bold !important;
}


.line.issuing {
    padding-left: 12px;
    background-color: white !important;
}

.line.issuing.line-a {
    grid-template-columns: 18px var(--sub-type-a) !important;
}

.line.issuing.line-b {
    grid-template-columns: 18px var(--type-b) !important;
}

.issuing:hover {
    background-color: #ffe1aa44 !important;
}

.head-issue.head-line {
    padding-left: 12px;
}

.head-issue.head-line.type-a {
    grid-template-columns: 18px var(--type-a) !important;
}

.head-issue.head-line.type-b {
    grid-template-columns: 18px var(--type-b) !important;
}


.head-issue.head-line.type-c {
    grid-template-columns: 18px var(--type-c) !important;
}


.sub-head-line.type-a {
    grid-template-columns: var(--sub-type-a) !important;
}

.sub-head-line.type-b {
    height: 32px;
    grid-template-columns: var(--type-b) !important;
}

.sub-head-line.type-c {
    height: 32px;
    grid-template-columns: var(--sub-type-c) !important;
}

.sub-head-line.sub-head-issue {
    padding-left: 12px;
}

.sub-head-line.sub-head-issue.type-a {
    grid-template-columns: 18px var(--sub-type-a) !important;
}

.sub-head-line.sub-head-issue.type-b {
    grid-template-columns: 18px var(--type-b) !important;
}

.accent.issuing {
    color: #ff7d37 !important;
    background-color: transparent !important;
}

.accent.issuing:hover {
    background-color: transparent !important;
}

.line.issuing.active-issue {
    background-color: #fbda6482 !important;
}

.active-issue input {
    accent-color: #ff5900;
    background-color: white;
    color: white;
    border-color: white;
}

.line.approved {
    background-color: #afecc8;
}

.accent.approved {
    color: #00752f !important;
}

.line.approved:hover {
    background-color: #67d091;
}

.line.focused {
    border: 3px solid #ff5900;
}

.issue-line-counter {
    position: absolute;
    background-color: white;
    border-radius: 10px;
    width: fit-content;
    padding: 4px 24px;
    right: 24px;
    bottom: 170px;
    display: grid;
    box-shadow: 0px 0px 12px 0px rgba(0, 0, 0, 0.174);
    grid-template-columns: 120px 24px 24px;
}

.issue-line-counter p {
    font-size: 12px;
    color: black;
    font-weight: bold;
}

.issue-line-counter button {
    cursor: pointer;
    margin-top: 10px;
    margin-left: 10px;
    height: 20px;
}

.icon-chevron-up {
    background-color: #ff7d3751;
    -webkit-mask-image: url(../../../../images/icons/chevron-up.svg);
    mask-image: url(../../../../images/icons/chevron-up.svg);
}

.icon-chevron-up:hover, .icon-chevron-down:hover {
    background-color: #ff5900;
}

.icon-chevron-down {
    background-color: #ff7d3751;
    -webkit-mask-image: url(../../../../images/icons/chevron-down.svg);
    mask-image: url(../../../../images/icons/chevron-down.svg);
}

.issue-line-counter span {
    color: #ff5900;
}