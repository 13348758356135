.tooltiptext {
    visibility: hidden;
    width: fit-content;
    background-color: rgb(52, 63, 62);
    color: #b0ffff;
    text-align: center;
    padding: 4px 8px;
    border-radius: 2px;
    font-size: 12px;
    font-family: 'Courier New', Courier, monospace;
   
    position: absolute;
    right: 36px;
    bottom: 6px;
    z-index: 10000;
}
  
  /* Show the tooltip text when you mouse over the tooltip container */
.tooltip:hover .tooltiptext {
    visibility: visible;
    max-width: 180px;
    overflow-wrap: break-word;
}