/* width */
::-webkit-scrollbar {
    width: 6px;
  }
  
  /* Track */
  ::-webkit-scrollbar-track {
    background: #f1f1f1;
  }
  
  /* Handle */
  ::-webkit-scrollbar-thumb {
    background: #05a5784b;
  }
  
  /* Handle on hover */
  ::-webkit-scrollbar-thumb:hover {
    background: #555;
  }

.side-bar h1 {
    font-size: 16px;
    margin: 0;
    margin-left: 60px;
}

.side-bar .nav-selected {
    background-color: #DAF5F0;
}


.logo-header {
    display: flex;
    margin-left: -16px;
    margin-top: -2px;
}

.logo-header h1 {
    margin: 0px;
    font-size: 16px;
}
.main-container {
    display: flex;
    overflow: hidden;
    max-height: 100vh;
    max-width: 100vw;
}

.side-bar {
    position: relative;
    width: 600px;
    height: calc(100vh - 40px);
    max-height: 100vh;
    max-width: 260px;
    padding:20px 0px 20px 40px;
    box-shadow: 2px 0px 12px 0px black;
    overflow-y: scroll;
    overflow-x: visible;
}

.side-bar img {
    width: 20px;
    height: 20px;
    margin-right: 20px;
    background-color: white;
}

.nav-tabs h2 {
    cursor: pointer;
    text-align: left;
    font-size: 12px;
    font-weight: 500;
    padding: 10px 0px 10px 60px;
    border-radius: 50px 0px 0px 50px;
    margin: 0;
}

.nav-tabs h2:hover {
    background-color: #96e5d7;
    color: white;  
}





.collapsed-user {
    position: absolute;
    right: 12px;
    top: 12px;
    width: 32px;
    height: 32px;
    border-radius: 50%;
}

.collapsed-user:hover {
    background-color: #0b9ba0;
}

.profile {
    display: grid;
    grid-template-columns: 100%;
}

.profile img {
    width: 32px;
    height: 32px;
    border-radius: 50%;
    margin-top: 5px;
    margin-left: 64px;
    background-color: #044e3f;
}
.profile {
    padding-top: 8px;
}

.user-info {
    top: 0px;
    width: calc(100% - 24px);
    position: absolute;
    right: 0;
    background-color: #2C665A;
    padding: 0px 12px 24px;
    z-index: 1000;
}

.user-info h1 {
    font-size: 16px;
    margin: 0;
    margin-top: 2px;
    text-align: center;
    font-weight: 500;
    color: #8be7d5;
}

.user-info h2 {
    margin: 0;
    font-size: 12px;
    font-weight: 500;
    color: #ffffff;
    text-align: center;
}

.user-info button {
    width: 80%;
    margin-left: 10%;
    margin-top: 12px;
    padding: 6px 0px;
    color: rgb(5, 109, 100);
    border-radius: 4px;
    border: none;
    background-color: #59eed0;
    cursor: pointer;
}

.user-info button:hover {
    background-color: #98f7e4;
}

.profile-icon.icon-cancel {
    position: absolute;
    right: 4px;
    top: -6px;
    width: 16px;
    height: 16px;
}


.icon-profile {
    cursor: pointer;
    border: none;
    height: 24px !important;
    width: 24px !important;
    margin-left: 4px;
    margin-top: 4px;
    -webkit-mask-repeat: no-repeat;
    mask-repeat: no-repeat;
    -webkit-mask-size: contain;
    mask-size: contain;
    
    background-color: #0b6568 !important;
    -webkit-mask-image: url(../../../images/icons/profile.svg);
    mask-image: url(../../../images/icons/profile.svg);
}

.icon-profile:hover {
    background-color: #ffffff !important;
}