:root {
    --action-section: #caf1f0;
}

.report-navigator {
    position: relative;
    width: calc(100vw - 300px);
    background-color: white;
}

/* width */
::-webkit-scrollbar {
    width: 6px;
    height: 0px;
  }
  
  /* Track */
  ::-webkit-scrollbar-track {
    background: #f1f1f1;
  }
  
  /* Handle */
  ::-webkit-scrollbar-thumb {
    background: #05a5784b;
  }
  
  /* Handle on hover */
  ::-webkit-scrollbar-thumb:hover {
    background: #555;
  }

.document-nav-tabs {
    padding: 0;
    width: 100%;
    max-width: 100%;
    display: inline-flex;
    flex-wrap: nowrap;
    background-color: #E5E5E5;
    overflow-x: scroll;
    margin-left: -1px;
}

.document-nav-tabs h1 {
    margin: 0;
    position: relative;
    display: flex;
    width: fit-content;
    min-width: fit-content;
    max-height: 12px;
    text-overflow: unset;
    cursor: pointer;
    font-size: 10px;
    padding: 12px 12px 12px 24px;
}

.document-nav-tabs h1::before {
    content: '';
    position: absolute;
    bottom: 30%;
    left: -1px;
    height: 40%;
    border-left: 1px solid gray;
}

.icon.icon-close-tab {
    cursor: pointer;
    width: 16px;
    height: 16px;
    margin-left: 8px;
    margin-top: -2px;
    display: inline-flex;
    background-color: #9b9b9b;
    -webkit-mask-image: url(../../../images/icons/cancel.svg);
    mask-image: url(../../../images/icons/cancel.svg);
}

.icon.icon-close-tab:hover {
    background-color: #555;
}

.icon.icon-options-tab {
    cursor: pointer;
    width: 14px;
    height: 14px;
    margin-left: 20px;
    margin-top: -1px;
    display: inline-flex;
    background-color: #9b9b9b;
    -webkit-mask-image: url(../../../images/icons/dots-vertical.svg);
    mask-image: url(../../../images/icons/dots-vertical.svg);
}

.icon.icon-options-tab:hover {
    background-color: rgb(255, 76, 76);
}

.document-nav-tabs h1:hover {
    background-color: var(--action-section) !important;
    color: black;
}

.active {
    border-radius: 8px 8px 0px 0px;
    background-color: white !important;
    padding-right: 24px !important;
    border: none !important;
    z-index: 1;
}

.active::before {
    border: none !important;
}

.empty-tab {
    color: gray;
    padding-right: 24px !important;
}

.report-content {
    position: relative;
    width: 100%;
    max-width: calc(100% - 36px);
    margin-top: 0;
    background-color: rgb(255, 255, 255);
    height: calc(100vh - 60px);
    max-height: calc(100vh - 60px);
    padding: 24px 0px 0px 36px;
}

.doc-info {
    margin-top: 12px;
    display: grid;
    padding: 12px 24px;
    background-color: var(--action-section);
    grid-template-columns: 35% 35%;
}

.doc-info h1 {
    color: #5f5f5f;
    font-family: monospace;
    font-style: italic;
    font-weight: 200;
    font-size: 11px;
    margin: 0;
}

.doc-info span {
    color: black;
    font-weight: 600 !important;
}

.header {
    margin-top: 0px;
    height: fit-content !important;
    padding: 0 !important;
    overflow: hidden !important;
}

.lines-container {
    height: 40vh;
    max-height: calc(90vh) !important;
    overflow-y: scroll;
    padding-bottom: 100px;
}

.export-container {
    max-height: 26vh;
    height: fit-content;
    background-color: var(--action-section);
    position: absolute;
    bottom: 0;
    right: 0;
    width: 100%;
    padding-bottom: 24px;
}

.report-tabs {
    position: absolute;
    top: -37px;
    display: flex;
}

.active-report {
    background-color: var(--action-section) !important;
}

.report-tab {
    cursor: pointer;
    border-radius: 10px 10px 0px 0px;
    border: 3px solid var(--action-section);
    border-bottom: none;
    display: flex;
    padding: 0px 24px;
    background-color: white;
}

.report-tab:hover {
    background-color: #95b9b3;
    border-color: #95b9b3;
}

.report-tabs h1 {
    font-family: monospace;
    margin: 8px;
    font-size: 16px;
    font-weight: 400;
}
.report-tabs img {
    width: 20px;
    height: 20px;
    margin-top: 7px;
    margin-left: 12px;

}

.icon-incomplete {
    background-color: #9b9b9b;
    -webkit-mask-image: url(../../../images/icons/dots.svg);
    mask-image: url(../../../images/icons/dots.svg);
}

.icon-issue {
    width: 22px !important;
    height: 22px !important;
    background-color: #ff5900;
    -webkit-mask-image: url(../../../images/icons/warning.svg);
    mask-image: url(../../../images/icons/warning.svg);
}

.icon-approved {
    background-color: #049B40;
    -webkit-mask-image: url(../../../images/icons/approved.svg);
    mask-image: url(../../../images/icons/approved.svg);
}

.actions-section {
    background-color: var(--action-section);
    display: grid;
    grid-template-columns: 50% 50%;
}

.download-section {
    padding: 0px 16px 12px 16px;
}

.download-section span {
    margin-left: 8px;
    color: #049B40;
}

.download-section p {
    font-size: 12px;
    font-weight: bold;
    margin-bottom: 8px;
    font-family: monospace;
    color: gray;
}

.download-section button {
    cursor: pointer;
    margin: 4px 4px;
    padding: 8px 12px;
    font-size: 12px;
    font-weight: bold;
    background-color: #CFF465;
    color: #0B6825;
    border-radius: 8px;
    font-family: monospace;
    border: none;
    box-shadow: 0px 2px 2px 0px rgba(95, 95, 95, 0.6);
}

.download-section button:hover {
    color: white;
    background-color: #98bd30;
}

.submit-section {
    display: grid;
    padding: 24px 12px;
    grid-template-columns: 55% 45%;
}

.submit-section.issue {
    background-color: var(--action-section);
    border: none !important;
}

.submit-section.issue:hover {
    background-color: transparent;
}

.submit-section.issue h1 {
    color: #ff5900;
}

.submit-section.issue button {
    background-color: #ff5900;
}
.submit-section.issue button:hover {
    background-color: #fe8645;
}

.submit-section h1 {
    font-size: 14px;
    color: #049B40;
    font-family: monospace;
}

.submit-section button {
    cursor: pointer;
    padding: 8px 24px;
    border-radius: 6px;
    border: none;
    width: 85%;
    margin-top: 6px;
    font-weight: bold;
    color: white;
    background-color: #049B40;
    font-family: monospace;
}

.submit-section button:hover {
    background-color: #1ed869;
}

.document-actions {
    position: absolute;
    right: 24px;
    top: 20px;
    max-width: 120px;
    
}

.document-actions button {
    color: white;
    font-weight: bold;
    margin-bottom: 6px;
    cursor: pointer;
    width: 100px;
    border-radius: 4px;
    padding: 8px 12px;
    font-family: monospace;
}

.approve {
    background-color: #049B40;
    border: 3px solid #049B40 !important;
    color: white !important;
}

.approve:hover {
    background-color: #20c562;
    color: white !important;
}

.issue {
    background-color: #ff9823;
    border: 3px solid #ff9823 !important;
    color: white !important;
}

.issue:hover {
    background-color: #fda745 !important;
    color: white !important;
}

.issue.active {
    background-color: #fdb460 !important;
    color: white !important;
}

.approve.active {
    background-color: #1ad464 !important;
    color: white !important;
}

.delete {
    border: 3px solid red !important;
    color: red !important;
    background-color: white;
}
.delete:hover {
    background-color: red;
    color: white !important;
} 

.navigation-menu {
    padding: 24px 0px 0px 36px;
    display: grid;
    grid-template-columns: 40% 10% 40%;
    background-color: rgb(255, 255, 255);
    height: 100vh;
}

.navigation-menu h1 {
    font-size: 16px;
    color: #b0b0b0;
}

.navigation-menu h2 {
    font-size: 14px;
}

.navigation-menu p {
    width: fit-content;
    padding: 2px 18px 2px 18px;
    border-radius: 8px;
    cursor: pointer;
    color: rgb(9, 82, 108);
    margin: 0;
    font-size: 12px;
    font-weight: bold;
}

.navigation-menu button {
    border: none;
    cursor: pointer;
    padding: 8px 12px;
    border-radius: 4px;
}
    

.navigation-menu p:hover {
    color:rgb(65, 154, 162);
}


/* Document Tab options */


.nav-options-menu {
    position: absolute;
    width: 260px;
    z-index: 10;
    top: 10px;
    background-color: white;
    padding: 8px 0px 12px;
    box-shadow: 0px 0px 10px 0px rgba(0, 0, 0, 0.288);
}

.nav-options-menu h1 {
    font-size: 14px;
}

.nav-option {
    padding: 0px 32px 0px 12px;
    cursor: pointer;
    display: grid;
    height: 32px;
    position: relative;
    grid-template-columns: 60% 40%;
}

.nav-options-menu p {
    line-height: 32px;
    cursor: pointer;
    font-size: 12px;
    margin: 0;
    width: fit-content;
    align-content: center;
    border-radius: 8px;
    text-align: left;
    padding-left: 12px;
}

.nav-option:hover {
    background-color: #d2dae6;
}


.nav-options-menu hr {
    background-color: white;
    border: none;
    border-bottom: 1px solid rgb(228, 228, 228);
    height: 2px;
    width: 80%;
}

.nav-option span {
    width: fit-content;
    line-height: 32px;
    cursor: pointer;
    font-size: 12px;
    color: rgb(163, 163, 163);
}

.report-alert {
    position: absolute;
    z-index: 10;
    background-color: rgba(0, 0, 0, 0.76);
    width: 100%;
    max-width: 100%;
    height: 100%;
}

.alert-card {
    background-color: white;
    position: absolute;
    width: fit-content;
    min-width: 300px;
    left: 100px;
    top: 40px;
    padding: 16px 32px;
    border-radius: 12px;
}

.alert-card h1 {
    font-size: 16px;
    text-align: center;
    max-width: 300px;
}

.alert-card pre {
    border: 1px solid gray;
    padding: 12px 24px;
    max-height: 40vh;
    overflow-y: scroll;
    text-align: left;
}

.alert-card p {
    font-size: 12px;
    text-align: center;
}


.alert-options {
    margin-top: 32px;
    display: grid;
    grid-template-columns: 45% 10% 45%;
}

.confirm-btn:hover {
    box-shadow: 0px 0px 8px 0px rgba(0, 0, 0, 0.514);
}

.alert-options button {
    border: none;
    cursor: pointer;
    padding: 12px 0px;
    border-radius: 8px;
    background-color: transparent;
}

.alert-options button:hover {
    background-color: rgb(228, 228, 228);
}


.versions-list p {
    text-align: center;
    cursor: pointer;
    margin: 0;
    padding: 12px 0px;
}

.versions-list p:hover {
    background-color: rgb(226, 226, 226);
}


.loading {
    position: absolute;
    top: 40px;
    left: 0;
    width: calc(100vw - 300px);
    max-width: calc(100vw - 300px);
    max-height: calc(100vh - 40px);
    height: calc(100vh - 40px);
}

.loading p {
    width: 200px;
    margin-left: calc(50% - 100px);
    text-align: center;
    font-weight: bold;
    font-size: 12px;
    font-family: 'Courier New', Courier, monospace;
}

.icon.icon-loading {
    margin-top: 40vh;
    margin-left: calc(50% - 32px);
    cursor: pointer;
    width: 48px;
    height: 48px;
    background-color: #11a0b9;
    -webkit-mask-image: url(../../../images/icons/Infinity.gif);
    mask-image: url(../../../images/icons/Infinity.gif);
}

@keyframes vanish {
    from {opacity: 0;}
    to {opacity: 1;}
}

.search-tab {
    animation-name: vanish;
    animation-duration: 1s;
    animation-iteration-count: 1;
    animation-direction: normal;
    position: absolute;
    background-color: white;
    width: 300px;
    padding: 24px;
    z-index: 100;
    right: 12px;
    top: 12px;
    border-radius: 8px;
    box-shadow: 0px 0px 8px rgba(0, 0, 0, 0.226);
}

.search-tab h2 {
    font-size: 14px;
    margin-bottom: 18px;
    margin-top: 0;
    font-family: system-ui, -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, Oxygen, Ubuntu, Cantarell, 'Open Sans', 'Helvetica Neue', sans-serif;
}

@media only screen and (max-height: 610px) {
    .lines-container {
        height: 40vh;
    }
}

@media only screen and (min-height: 900px) {
    .lines-container {
        height: 60vh;
    }
}

.select-box-container {
    overflow-y: scroll;
    max-height: 76vh;
    margin-bottom: 22px;
}

.select-item {
    margin-top: -1px;
    background-color: white;
    display: grid;
    grid-template-columns: 23px 110px 110px 60px 60px;
    padding: 12px 14px;
    color: #646464 !important;
    cursor:pointer;
    border-top: 1px solid rgb(179, 179, 179);
    border-bottom: 1px solid rgb(179, 179, 179);
}

.select-item.selected {
    background-color: #dee8f3 !important;
    border-top: 1px solid rgb(179, 179, 179) !important;
    border-bottom: 1px solid rgb(179, 179, 179) !important;
}

.select-item:hover {
    background-color: #e7eeed;
    color:  #646464 !important;
}

.select-item p {
    width: 100%;
    text-align: center;
    color:  #646464 !important;
    font-weight: 300;
    padding: 4px;
}

.select-item button {
    font-size: 12px;
    height: 25px;
    padding: 2px 2px;
    margin: 0px;
    margin-right: 4px;
}

.btn-loading {
    color: white;
    padding: 2px 16px 2px 2px !important;
    background-color: #26cce9;
}

.icon.icon-rolling {
    position: absolute;
    width: 12px;
    height: 12px;
    top: 6px;
    display: block;
    -webkit-mask-image: url(../../../images/icons/rolling.gif);
    mask-image: url(../../../images/icons/rolling.gif);
    height: 12px;
    background-color: red;
    right: 4px;
}

.select-item button:hover {
    background-color: cornflowerblue;
    color: white;
}

.select-item p:hover {
    color:  #646464 !important;
}

.select-item input {
    cursor: pointer;
}

.download-controls {
    margin-top: 20px;
}

.download-controls button {
    position: relative;
    background-color: #059eb9;
    color: white;
    font-weight: 500;
    margin: 8px;
}

.navigation-menu.download {
    grid-template-columns: 46% 50%;
}

.download-controls button:hover {
    filter: saturate(1.3);
}

.icon-file {
    margin-left: 17px !important;
    height: 23px !important;
    width: 23px !important;
    background-color:  #646464;
    -webkit-mask-image: url(../../../images/icons/file.svg);
    mask-image: url(../../../images/icons/file.svg);
}

.icon-download {
    margin-left: 17px !important;
    height: 23px !important;
    width: 23px !important;
    background-color: #059eb9;
    -webkit-mask-image: url(../../../images/icons/download-square.svg);
    mask-image: url(../../../images/icons/download-square.svg);
}

.icon-download:hover {
    background-color: #04d3f8;
}

.download-loading.loading {
    position: relative;
    width: 100%;
}

.export-window {
    padding: 12px 24px;
}

.export-window label {
    font-size: 12px;
    margin-right: 20px;
}

.export-window input {
    border: 1px solid gray;
    padding: 8px 10px;
    border-radius: 6px;
}

.download-loading img {
    margin-top: 50px !important;
    margin-left: calc(50% - 12px) !important;
}